.testimonial-card1-testimonial-card {
    display: flex;
    padding: 32px;
    max-width: 1400px;
    background: #fff;
    /* background-color: rgba(18, 18, 18, 0.1); */
    background-image: url("/playground_assets/45-degree-fabric-light.png");
    box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .testimonial-card1-icon {
    width: 2rem;
    margin-bottom: 32px;
  }
  .testimonial-card1-testimonial {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .testimonial-card1-text {
    color: #595959;
    text-align: center;
    margin-bottom: 48px;
  }
  .testimonial-card1-text1 {
    font-size: 1.5rem;
    font-weight: 300;
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .testimonial-card1-text2 {
    color: #595959;
    font-weight: 600;
    margin-bottom: var(--dl-space-space-unit);
    text-transform: uppercase;
  }
  .testimonial-card1-image {
    width: 96px;
    height: 96px;
    object-fit: cover;
    border-radius: var(--dl-radius-radius-round);
  }
  
  
  
  @media(max-width: 991px) {
    .testimonial-card1-text2 {
      align-self: center;
    }
  }
  @media(max-width: 767px) {
    .testimonial-card1-testimonial-card {
      padding-left: 32px;
      padding-right: 32px;
    }
  }
  @media(max-width: 479px) {
    .testimonial-card1-testimonial-card {
      padding-top: 32px;
      padding-left: var(--dl-space-space-unit);
      padding-right: var(--dl-space-space-unit);
      padding-bottom: 32px;
    }
    .testimonial-card1-icon {
      margin-bottom: var(--dl-space-space-unit);
    }
    .testimonial-card1-text {
      margin-bottom: 32px;
    }
    .testimonial-card1-text1 {
      margin-bottom: var(--dl-space-space-halfunit);
    }
    .testimonial-card1-text2 {
      margin-bottom: var(--dl-space-space-unit);
    }
  }
  