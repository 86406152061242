.container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.gallery {
  width: 100%;
  height: 1200px;
  display: grid;
  grid-gap: var(--dl-space-space-unit);
  max-width: 1400px;
  align-items: flex-start;
  padding-top: 32px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 32px;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-areas: "a b b" "a b b" "a c d" "a c d"  "e e d" "e e f" "e e f";
  grid-template-columns: 1fr 1fr 1fr;
}
.container01 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  opacity: 1;
  grid-area: a;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url("/playground_assets/constr1.gif");
  background-position: center;
}
.container01:hover {
  transform: scale(0.98);
}
.container02 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
}
.text {
  color: var(--dl-color-gray-white);
  font-size: 1.5rem;
  font-weight: 600;
}
.text01 {
  color: var(--dl-color-gray-white);
  margin-top: var(--dl-space-space-halfunit);
}
.container03 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  grid-area: b;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url("/playground_assets/consult1.jpg");
  background-position: bottom;
}
.container03:hover {
  transform: scale(0.98);
}
.container04 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
}
.text03 {
  color: var(--dl-color-gray-white);
  font-size: 1.5rem;
  font-weight: 600;
}
.text04 {
  color: var(--dl-color-gray-white);
  margin-top: var(--dl-space-space-halfunit);
}
.container05 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  grid-area: c;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url("/playground_assets/training.jpg");
  background-position: center;
}
.container05:hover {
  transform: scale(0.98);
}
.container06 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 32px;
  align-items: flex-start;
  flex-direction: column;
}
.text06 {
  color: var(--dl-color-gray-white);
  font-size: 1.5rem;
  font-weight: 600;
}
.text07 {
  color: var(--dl-color-gray-white);
  margin-top: var(--dl-space-space-halfunit);
}
.container07 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  grid-area: d;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url("/playground_assets/flooring1.jpg");
  background-position: center;
}
.container07:hover {
  transform: scale(0.98);
}
.container08 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
}
.text09 {
  color: var(--dl-color-gray-white);
  font-size: 1.5rem;
  font-weight: 600;
}
.text10 {
  color: var(--dl-color-gray-white);
  margin-top: var(--dl-space-space-halfunit);
}
.container09 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  grid-area: e;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url("/playground_assets/waterproof.webp");
  background-position: center;
}
.container09:hover {
  transform: scale(0.98);
}
.container10 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
}
.text12 {
  color: var(--dl-color-gray-white);
  font-size: 1.5rem;
  font-weight: 600;
}
.text13 {
  color: var(--dl-color-gray-white);
  margin-top: var(--dl-space-space-halfunit);
}
.container11 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  grid-area: f;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url("/playground_assets/repair.jpg");
  background-position: center;
}
.container11:hover {
  transform: scale(0.98);
}
.container12 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
}
.text15 {
  color: var(--dl-color-gray-white);
  font-size: 1.5rem;
  font-weight: 600;
}
.text16 {
  color: var(--dl-color-gray-white);
  margin-top: var(--dl-space-space-halfunit);
}
@media(max-width: 991px) {
  .container02, .container04, .container08, .container10, .container12 {
    padding: 32px;
  }
  .gallery {
    grid-gap: var(--dl-space-space-unit);
  }
  .text {
    text-align: center;
    align-self: center;
    font-size: 1.2rem;
  }
  .text01 {
    text-align: center;
    font-size: 0.8rem;
    margin-top: 16px;
  }
  .text03 {
    text-align: center;
    align-self: center;
    font-size: 1.2rem;
  }
  .text04 {
    text-align: center;
    font-size: 0.8rem;
    margin-top: 16px;
  }
  .text06 {
    text-align: center;
    align-self: center;
    font-size: 1.2rem;
  }
  .text07 {
    text-align: center;
    font-size: 0.8rem;
    margin-top: 16px;
  }
  .text09 {
    text-align: center;
    align-self: center;
    font-size: 1.2rem;
  }
  .text10 {
    text-align: center;
    font-size: 0.8rem;
    margin-top: 16px;
  }
  .text12 {
    text-align: center;
    align-self: center;
    font-size: 1.2rem;
  }
  .text13 {
    text-align: center;
    font-size: 0.8rem;
    margin-top: 16px;
  }
  .text15 {
    text-align: center;
    align-self: center;
    font-size: 1.2rem;
  }
  .text16 {
    text-align: center;
    font-size: 0.8rem;
    margin-top: 16px;
  }
}
@media(max-width: 767px) {
  .gallery {
    height: auto;
    display: flex;
    padding: 32px;
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .gallery {
    width: 100%;
    padding: var(--dl-space-space-unit);
  }
}
