.products-catalog{
    /* background-image: url('https://images.unsplash.com/photo-1503149779833-1de50ebe5f8a?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDE2fHxzd2lzcyUyMGNoZWVzZSUyMHBsYW50fGVufDB8fHx8MTYyNTg0MTU0Mg&ixlib=rb-1.2.1&h=1200'); */
    /* background-color: rgba(0, 0, 0, 0.2) !important; */
    /* background-image: url('https://media.istockphoto.com/id/582256640/photo/oil-refinery-chemical-petrochemical-plant.jpg?s=612x612&w=0&k=20&c=BEdsHVe2vUfzRTb9KcsCS_tCH6_R_nKLKkOQCht8AKo='); */
    /* background-color: black; */
    /* background-color: white; */
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.8)), url('https://media.istockphoto.com/id/582256640/photo/oil-refinery-chemical-petrochemical-plant.jpg?s=612x612&w=0&k=20&c=BEdsHVe2vUfzRTb9KcsCS_tCH6_R_nKLKkOQCht8AKo=');
    color: white;
    background-size: contain;
}

.products-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  .products-catalog-content{
    background-color: #fff;
    padding: 2rem 0.5rem;    
  }
  .products-catalog-content h1{
    color: black;
    /* margin-left: 1rem; */
    text-align: center;
    margin: 1.5rem;
    margin-top: 0;
  }
  .products-hero {
    width: 100%;
    display: flex;
    padding: 48px;
    min-height: 80vh;
    align-items: center;
    flex-direction: column;
    background-size: cover;
    justify-content: center;
    background-image: url("https://images.unsplash.com/photo-1503149779833-1de50ebe5f8a?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDE2fHxzd2lzcyUyMGNoZWVzZSUyMHBsYW50fGVufDB8fHx8MTYyNTg0MTU0Mg&ixlib=rb-1.2.1&h=1200");
    background-position: center;
  }
  .products-text {
    color: var(--dl-color-gray-white);
    font-size: 0.75rem;
    letter-spacing: 2px;
  }
  .products-text01 {
    text-transform: uppercase;
  }
  .products-text02 {
    color: var(--dl-color-gray-white);
    font-size: 3rem;
    max-width: 450px;
    margin-top: 32px;
    text-align: center;
    margin-bottom: 32px;
  }
  .products-button {
    color: var(--dl-color-gray-white);
    transition: 0.3s;
    padding-top: var(--dl-space-space-unit);
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: var(--dl-space-space-unit);
    background-color: var(--dl-color-gray-black);
  }
  .products-button:hover {
    transform: scale(1.02);
  }
  .products-banner {
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: 64px;
    padding-left: 48px;
    padding-right: 48px;
    flex-direction: column;
    padding-bottom: 64px;
    background-size: cover;
    justify-content: space-between;
    background-image: url('https://images.unsplash.com/photo-1485628390555-1a7bd503f9fe?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDE3fHxhcmNoaXRlY3R1cmUlMjBtaW5pbWFsfGVufDB8fHx8MTYyNjE4NDA0Mg&ixlib=rb-1.2.1&h=1200');
    background-position: center;
  }
  .products-text03 {
    font-size: 0.75rem;
    text-align: center;
    font-weight: 600;
    margin-bottom: 32px;
    letter-spacing: 2px;
  }
  .products-text04 {
    text-transform: uppercase;
  }
  .products-text05 {
    font-size: 3rem;
    text-align: center;
    margin-bottom: var(--dl-space-space-unit);
  }
  .products-text06 {
    max-width: 1400px;
    text-align: center;
    margin-bottom: 32px;
  }
  .products-container01 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: center;
  }
  .products-textinput {
    font-size: 0.75rem;
    border-color: #595959;
  }
  .products-button1 {
    color: var(--dl-color-gray-white);
    font-size: 0.75rem;
    transition: 0.3s;
    margin-left: var(--dl-space-space-unit);
    background-color: var(--dl-color-gray-black);
  }
  .products-button1:hover {
    color: var(--dl-color-gray-black);
    background-color: transparent;
  }
  .products-gallery {
    width: 100%;
    display: grid;
    padding: 48px;
    grid-gap: var(--dl-space-space-unit);
    max-width: 1400px;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .products-gallery1 {
    width: 100%;
    height: 1200px;
    display: grid;
    grid-gap: var(--dl-space-space-unit);
    max-width: 1400px;
    align-items: flex-start;
    padding-top: 32px;
    padding-left: 48px;
    padding-right: 48px;
    padding-bottom: 32px;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas: "a b b" "a b b" "a c d" "a c d"  "e e d" "e e f" "e e f";
    grid-template-columns: 1fr 1fr 1fr;
    background-color: #fff !important;
  }
  .products-container02 {
    flex: 0 0 auto;
    width: 100%;
    height: 100%;
    display: flex;
    opacity: 1;
    grid-area: a;
    transition: 0.3s;
    align-items: flex-start;
    flex-direction: column;
    background-size: cover;
    background-image: url("https://www.aboutcivil.org/sites/default/files/2017-09/ready-mix-concrete.JPG");
    background-position: center;
  }
  .products-container02:hover {
    transform: scale(0.98);
  }
  .products-container03 {
    width: 100%;
    height: 100%;
    display: flex;
    padding: var(--dl-space-space-unit);
    align-items: flex-start;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .products-text13 {
    color: var(--dl-color-gray-white);
    font-size: 1.5rem;
    font-weight: 600;
  }
  .products-text14 {
    color: var(--dl-color-gray-white);
    margin-top: var(--dl-space-space-halfunit);
  }
  .products-container04 {
    flex: 0 0 auto;
    width: 100%;
    height: 100%;
    display: flex;
    grid-area: b;
    transition: 0.3s;
    align-items: flex-start;
    flex-direction: column;
    background-size: cover;
    background-image: url("https://5.imimg.com/data5/EQ/SR/XK/SELLER-73327011/20mm-40mm-10mm-aggregates-500x500.jpg");
    background-position: center;
  }
  .products-container04:hover {
    transform: scale(0.98);
  }
  .products-container05 {
    width: 100%;
    height: 100%;
    display: flex;
    padding: var(--dl-space-space-unit);
    align-items: flex-start;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .products-text16 {
    color: var(--dl-color-gray-white);
    font-size: 1.5rem;
    font-weight: 600;
  }
  .products-text17 {
    color: var(--dl-color-gray-white);
    margin-top: var(--dl-space-space-halfunit);
  }
  .products-container06 {
    flex: 0 0 auto;
    width: 100%;
    height: 100%;
    display: flex;
    grid-area: c;
    transition: 0.3s;
    align-items: flex-start;
    flex-direction: column;
    background-size: cover;
    background-image: url("https://1.bp.blogspot.com/-htGZGrMYTL0/XkPop8fkxDI/AAAAAAAABGY/uoXIzcPKn4c2Ndwxa93kKIir_yJM4lsgwCLcBGAsYHQ/s400/M%2Bsand%2B2.jpg");
    background-position: center;
  }
  .products-container06:hover {
    transform: scale(0.98);
  }
  .products-container07 {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 32px;
    align-items: flex-start;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.3);
  }
  .products-text19 {
    color: var(--dl-color-gray-white);
    font-size: 1.5rem;
    font-weight: 600;
  }
  .products-text20 {
    color: var(--dl-color-gray-white);
    margin-top: var(--dl-space-space-halfunit);
  }
  .products-container08 {
    flex: 0 0 auto;
    width: 100%;
    height: 100%;
    display: flex;
    grid-area: d;
    transition: 0.3s;
    align-items: flex-start;
    flex-direction: column;
    background-size: cover;
    background-image: url("https://5.imimg.com/data5/SELLER/Default/2021/8/BW/NT/PV/136404297/p-sand-500x500.jpg");
    background-position: center;
  }
  .products-container08:hover {
    transform: scale(0.98);
  }
  .products-container09 {
    width: 100%;
    height: 100%;
    display: flex;
    padding: var(--dl-space-space-unit);
    align-items: flex-start;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.4);
  }
  .products-text22 {
    color: var(--dl-color-gray-white);
    font-size: 1.5rem;
    font-weight: 600;
  }
  .products-text23 {
    color: var(--dl-color-gray-white);
    margin-top: var(--dl-space-space-halfunit);
  }
  .products-text30{
    margin-top: var(--dl-space-space-halfunit);
    color: black;
  }
  .products-container10 {
    flex: 0 0 auto;
    width: 100%;
    height: 100%;
    display: flex;
    grid-area: e;
    transition: 0.3s;
    align-items: flex-start;
    flex-direction: column;
    background-size: cover;
    background-image: url("https://images.jdmagicbox.com/rep/b2b/granular-sub-base/granular-sub-base-1.jpg");
    background-position: center;
  }
  .products-container10:hover {
    transform: scale(0.98);
  }
  .products-container11 {
    width: 100%;
    height: 100%;
    display: flex;
    padding: var(--dl-space-space-unit);
    align-items: flex-start;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .products-text25 {
    color: var(--dl-color-gray-white);
    font-size: 1.5rem;
    font-weight: 600;
  }
  .products-text26 {
    color: var(--dl-color-gray-white);
    margin-top: var(--dl-space-space-halfunit);
  }
  .products-container12 {
    flex: 0 0 auto;
    width: 100%;
    height: 100%;
    display: flex;
    grid-area: f;
    transition: 0.3s;
    align-items: flex-start;
    flex-direction: column;
    background-size: cover;
    background-image: url("https://5.imimg.com/data5/VH/KM/MY-69792353/wmm-wet-mix-macadam-500x500.png");
    background-position: center;
  }
  .products-container12:hover {
    transform: scale(0.98);
  }
  .products-container13 {
    width: 100%;
    height: 100%;
    display: flex;
    padding: var(--dl-space-space-unit);
    align-items: flex-start;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .products-text28 {
    color: var(--dl-color-gray-white);
    font-size: 1.5rem;
    font-weight: 600;
  }
  .products-text29 {
    color: var(--dl-color-gray-white);
    margin-top: var(--dl-space-space-halfunit);
  }
  .products-banner {
    width: 100%;
    display: flex;
    flex-direction: row;
    /* padding: 48px; */
    padding: 0;
    max-width: 1400px;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border-top: 1px solid #ccc;
    background-image: url('https://images.unsplash.com/photo-1503149779833-1de50ebe5f8a?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDE2fHxzd2lzcyUyMGNoZWVzZSUyMHBsYW50fGVufDB8fHx8MTYyNTg0MTU0Mg&ixlib=rb-1.2.1&h=1200');
    background-image: url('https://media.istockphoto.com/id/582256640/photo/oil-refinery-chemical-petrochemical-plant.jpg?s=612x612&w=0&k=20&c=BEdsHVe2vUfzRTb9KcsCS_tCH6_R_nKLKkOQCht8AKo=');
    background-position-y: bottom;
  }
  .products-container15 {
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-right: 96px;
    flex-direction: column;
    justify-content: space-between;
    /* background-color: rgba(0, 0, 0, 0.4); */
  }
  .products-text21 {
    text-align: center;
    font-weight: 600;
    margin-bottom: var(--dl-space-space-unit);
    color: black;
    color: white;
  }
  .products-text30 {
    margin-right: 48px;
    /* color: black; */
    color: white
  }
  .products-text23 {
    margin-right: 48px;
    /* color: black; */
  }
  .products-container16 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    margin-top: 32px;
    align-items: flex-start;
    flex-direction: row;
    justify-content: flex-start;
  }
  .products-textinput {
    font-size: 0.75rem;
    border-color: #595959;
    border-radius: 4px;
  }
  .products-button {
    font-size: 0.75rem;
    transition: 0.3s;
    margin-left: var(--dl-space-space-unit);
    box-shadow: 0 0 7px -1px #fff;
    /* border-color: #595959; */
    border: 0;
  }
  .products-button:hover {
    color: var(--dl-color-gray-white);
    background-color: var(--dl-color-gray-black);
  }
  .products-image1 {
    width: 300px;
    height: 300px;
    object-fit: cover;
    flex-shrink: 0;
    border-radius: 3px;
  }
  .products-gallery-card5 {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .products-container015 {
    width: 100%;
    height: 100%;
    display: flex;
    opacity: 0;
    z-index: 1;
    transition: 0.3s;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    background-color: #00000096;
  }
  .products-container015:hover {
    opacity: 1;
  }
  .products-container025 {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 32px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .products-text5 {
    color: var(--dl-color-gray-white);
    font-style: normal;
    text-align: center;
    font-weight: 500;
    margin-bottom: 24px;
  }
  .products-text015 {
    color: var(--dl-color-secondary-100);
    text-align: center;
    margin-bottom: 24px;
  }
  .products-text025 {
    color: var(--dl-color-secondary-100);
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
  }
  .products-image5 {
    top: 0px;
    left: auto;
    right: 0px;
    width: 100%;
    bottom: auto;
    height: 100%;
    position: absolute;
    object-fit: cover;
  }
.special-cards{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  overflow-x: auto;
  gap: 20px;
  /* flex-wrap: wrap; */
}
@media screen and (max-width: 768px) {
  .special-cards{
    grid-template-columns: 1fr;
  }
  .products-container03, .products-container05, .products-container07, .products-container09, .products-container11, .products-container13{
    padding: 28px !important;
  }
 
}
.special-card{
  width: 100%;
  /* flex-grow: 1; */
  min-width: 15rem;
  /* flex-grow: 1; */
  min-height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.2);
  margin: 6px 10px;
  position: relative;
}
.special-card1{
  background-image: url('https://i0.wp.com/www.pronil.com/wp-content/uploads/2020/01/Pronil-ACR-hydrophilic-waterstop-01.jpg?fit=360%2C240&ssl=1');
  background-size: cover;
}
.special-card2{
  background-image: url('https://www.thoughtco.com/thmb/HRRmPjoGygm28L1cprIsllJAlkY=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/24322793889_db8cc4450e_k-b1aa4260cd76435b97c953c992e58c93.jpg');
  background-size: cover;
}
.special-card3{
  background-image: url('https://5.imimg.com/data5/LC/BX/MY-1969056/construction-chemicals-500x500.jpg');
  background-position: center;
  background-size: cover;
}
.special-card4{
  background-image: url('https://trpreadymix.com/wp-content/uploads/2019/07/concrete-products-textile-fiber-reinforced-concrete-1024x682.jpeg');
  background-position: center;
  background-size: cover;
}
.special-card5{
  background-image: url('/playground_assets/concrete-pump.jpg');
  background-position: center;
  background-size: cover;
}
.special-card6{
  background-image: url('/playground_assets/micro-silica.jpg');
  background-position: center;
  background-size: cover;
}
.special-card-content{
  padding: 22px;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.special-card-content button{
  width: fit-content;
  height: fit-content;
  background-color: #fff;
  color: black;
  padding: 9.5px 19px;
  margin-top: 1rem;
  cursor: pointer;
  transition-duration: 280ms;
}
.special-card-content button:hover{
  /* background-color: #000; */
  /* transform: scaleX(1.1); */
  padding: 9.5px 23px;
  background: linear-gradient(90deg, rgba(0,0,0,1) 0%, #fff 100%);
  color: #fff;
}
.special-card-content button span{
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}

  @media(max-width: 991px) {
    .products-text06 {
      width: 100%;
    }
    .products-gallery {
      grid-template-columns: 1fr 1fr;
    }
    .products-gallery1 {
      grid-gap: var(--dl-space-space-unit);
    }
    .products-text13 {
      text-align: center;
    }
    .products-text14 {
      text-align: center;
    }
    .products-text16 {
      text-align: center;
    }
    .products-text17 {
      text-align: center;
    }
    .products-text19 {
      text-align: center;
    }
    .products-text20 {
      text-align: center;
    }
    .products-text22 {
      text-align: center;
    }
    .products-text23 {
      text-align: center;
    }
    .products-text25 {
      text-align: center;
    }
    .products-text26 {
      text-align: center;
    }
    .products-text28 {
      text-align: center;
    }
    .products-text29 {
      text-align: center;
    }
    .products-banner {
        flex-direction: column;
      }
      .products-container15 {
        align-items: center;
        margin-right: 0px;
        margin-bottom: 48px;
      }
      .products-text23 {
        text-align: center;
        margin-left: 48px;
      }
      .products-container16 {
        justify-content: center;
      }
      .products-image1 {
        width: 288px;
        height: 288px;
      }
  }
  @media(max-width: 767px) {
    .special-cards{
      flex-direction: column;
    }
    .special-card{
      margin: 6px 0px;
    }
    .products-catalog-content{
      padding-top: 1rem;
    }
    .products-catalog-content h1{
      font-size: 1.6rem;
      margin-top: 0.5rem;
    }
    .header-header{
      margin-bottom: 0 !important;
      border-bottom: #595959 solid 0.7px;
    }
    .special-card-content{
      position: absolute;
      bottom: 0;
      height: fit-content;
    }
    .trial-div2 {
      flex-direction: column !important;
    }
    .products-image1{
      display: none;
    }
    .products-hero {
      padding-left: 32px;
      padding-right: 32px;
    }
    .products-banner {
      padding: 0 !important;
    }
    .products-text21{
      margin-left: 0px;
      text-align: justify;
    }
    .products-text13, .products-text16, .products-text19, .products-text22, .products-text25, .products-text28{
      font-size: 1.2rem;
      align-self: center ;
    }
    .products-text14, .products-text17, .products-text20, .products-text23, .products-text26, .products-text29{
      font-size: 0.8rem;
    }
    .products-text06 {
      padding-left: var(--dl-space-space-unit);
      padding-right: var(--dl-space-space-unit);
    }
    .products-gallery {
      padding-left: 32px;
      padding-right: 32px;
    }
    .products-gallery1 {
      height: auto;
      display: flex;
      padding: 32px;
      flex-direction: column;
    }
    .products-banner {
        padding-left: 32px;
        padding-right: 32px;
      }
      .products-text23 {
        margin-left: var(--dl-space-space-unit);
        margin-right: var(--dl-space-space-unit);
      }
  }
  @media(max-width: 479px) {
    .products-hero {
      padding-top: 32px;
      padding-left: var(--dl-space-space-unit);
      padding-right: var(--dl-space-space-unit);
      padding-bottom: 32px;
    }
    .products-banner {
      padding-top: 48px;
      padding-left: var(--dl-space-space-unit);
      padding-right: var(--dl-space-space-unit);
      padding-bottom: 48px;
    }
    .products-container01 {
      align-items: center;
      flex-direction: column;
    }
    .products-button1 {
      margin-top: var(--dl-space-space-unit);
      margin-left: 0px;
    }
    .products-gallery {
      padding-top: 32px;
      padding-left: var(--dl-space-space-unit);
      padding-right: var(--dl-space-space-unit);
      padding-bottom: 32px;
    }
    .products-gallery1 {
      width: 100%;
      padding: var(--dl-space-space-unit);
    }
    .products-banner {
        padding-top: 32px;
        padding-left: var(--dl-space-space-unit);
        padding-right: var(--dl-space-space-unit);
        padding-bottom: 32px;
      }
      .products-container16 {
        align-items: center;
        flex-direction: column;
      }
      .products-button {
        margin-top: var(--dl-space-space-unit);
        margin-left: 0px;
      }
      .products-image1 {
        width: 192px;
        height: 192px;
      }
  }
