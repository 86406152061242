.clients-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: flex-start;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    background: #232323;
  }
  .grid{
    display: grid;
  }
  .grid-cols-4{
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  @media screen and (max-width: 691px) {
    .grid-cols-4{
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }    
  }
  @media screen and (max-width: 500px) {
    .grid-cols-4{
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }    
  }
  .gap-4{
    grid-gap: 1.7rem;
  }
  @media screen and (max-width: 500px) {
    .gap-4{
      grid-gap: 0.5rem;
    }        
  }
  .clients-features {
    width: 100%;
    display: flex;
    padding: 48px;
    max-width: 1400px;
    flex-direction: column;
    background-color: #fff;
    background-image: url("/playground_assets/blu-stripes.png");
  }
  .clients-text {
    color: var(--dl-color-secondary-100);
    font-size: 3rem;
    margin-bottom: 48px;
  }
  .clients-container1 {
    flex: 0 0 auto;
    width: 100%;
    display: grid;
    grid-gap: 32px;
    align-items: flex-start;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  @media screen and (max-width: 591px) {
    .clients-text1{
        font-size: 2.1rem !important;
        line-height: 3rem;
    }
    .clients-text4{
        margin-bottom: 1rem;
    }
    .clients-text{
        font-size: 2.5rem !important;
        line-height: 3.5rem;
        margin-top: 0.5rem;
    }
    .clients-container1, .clients-container2{
        grid-template-columns: 1fr !important;
    }
  }
  .clients-container2 {
    flex: 0 0 auto;
    width: 100%;
    display: grid;
    grid-gap: 32px;
    margin-top: var(--dl-space-space-tripleunit);
    align-items: flex-start;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .clients-testimonial {
    width: 100%;
    border: 2px dashed rgba(120, 120, 120, 0.4);
    display: flex;
    background-size: cover;
    justify-content: center;
    background-image: url("https://images.unsplash.com/photo-1503149779833-1de50ebe5f8a?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDE2fHxzd2lzcyUyMGNoZWVzZSUyMHBsYW50fGVufDB8fHx8MTYyNTg0MTU0Mg&ixlib=rb-1.2.1&h=1200");
    background-position: center;
  }
  .clients-testimonial1 {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #232323;
  }
  .clients-container3 {
    width: 100%;
    display: flex;
    padding: 48px;
    max-width: 1400px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 3.5rem;
  }
  .clients-text1 {
    color: var(--dl-color-gray-white);
  }
  .clients-text4 {
    color: var(--dl-color-gray-white);
    font-size: 0.75rem;
    max-width: 600px;
    margin-top: var(--dl-space-space-unit);
    text-align: center;
    margin-bottom: 32px;
  }
  .clients-container4 {
    width: 100%;
    display: grid;
    grid-gap: 32px;
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media(max-width: 991px) {
    .clients-text {
      align-self: center;
    }
    .clients-container1 {
      grid-template-columns: 1fr 1fr;
    }
    .clients-container2 {
      grid-template-columns: 1fr 1fr;
    }
    .clients-text1 {
      text-align: center;
    }
    .clients-text4 {
      text-align: center;
    }
    .clients-container4 {
      grid-template-columns: 1fr;
    }
  }
  @media(max-width: 767px) {
    .clients-features {
      padding-left: 32px;
      padding-right: 32px;
    }
    .clients-container3 {
      padding-left: 32px;
      padding-right: 32px;
    }
  }
  @media(max-width: 479px) {
    .clients-features {
      padding-top: 32px;
      padding-left: var(--dl-space-space-unit);
      padding-right: var(--dl-space-space-unit);
      padding-bottom: 32px;
    }
    .clients-container1 {
      grid-gap: var(--dl-space-space-halfunit);
    }
    .clients-container2 {
      grid-gap: var(--dl-space-space-halfunit);
    }
    .clients-container3 {
      padding-top: 32px;
      padding-left: var(--dl-space-space-unit);
      padding-right: var(--dl-space-space-unit);
      padding-bottom: 32px;
    }
  }

  .clients-container8{
    width: 100%;
    display: flex !important;
    padding: 48px;
    max-width: 1400px;
    flex-direction: column;
  }