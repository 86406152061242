.upper_right {
    margin-right: 80px;
    text-align: justify;
    padding-left: 60px;
    color: black;
    /* margin-bottom: 60px; */
    /* padding-bottom: 30px; */
    /* border-bottom: 1px solid #d5d5d5; */
}

.upper_right h1 {
    text-align: left;
}

@media screen and (max-width: 767px) {
    .upper_right {
        margin-right: 0;
        text-align: left;
        padding-left: 0;
    }
    .sep-cat-img{
        width: 100%;
        margin-left: 0;
        padding-left: 0;
        padding-top: var(--dl-space-space-halfunit);
        padding-left: 0;
        padding-right: 0;
    }    
    .upper{
        margin-top: 0;
        padding-top: 0 !important;
        padding-left: var(--dl-space-space-halfunit);
        padding-right: var(--dl-space-space-halfunit);
        flex-direction: column;
    }
    .main{
        margin-left: 0 !important;
        margin-right: 0 !important;
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
    }
    .upper_right h1 {
        font-size: 1.8rem;
        font-weight: 700;
        margin-top: 1rem;
        padding-left: var(--dl-space-space-unit);
    }
    .upper_right span {
        justify-content: flex-start !important;
        padding-left: var(--dl-space-space-unit);
    }
    .upper_right p{
        font-size: 0.9rem;
        font-weight: 300;
        padding-left: var(--dl-space-space-unit);
        padding-right: var(--dl-space-space-unit);
        margin-top: 20px !important;
    }
    .dialog {
        max-width: 90vw !important;
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important;
    }
    h2 {
        line-height: 2rem;
        margin-top: 0.8rem;
    }
    .input {
        max-width: 90%;
    }
}
.upper_right h1 {
    font-size: 2.2rem;
    font-weight: 700;
    margin-bottom: 0;
}

.upper_right p{
    font-size: 1rem;
    font-weight: 300;
    margin-top: 20px;
}

.catalogue { 
    width: 100%;
    display: grid;
    grid-gap: var(--dl-space-space-twounits);
  /* max-width: var(--dl-size-size-maxwidth); */
    grid-template-columns: 1fr 1f 1fr 1fr;
    border-top: 1px solid #e4e4e4;
    padding-top: 50px;
}

.products-gallery {
    display: grid;
    grid-gap: var(--dl-space-space-unitandhalf);
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

@media screen and (max-width: 500px) {
    .products-gallery {
        grid-template-columns: 1fr;
    }
}