.blog-post-card {
    width: 95%;
    display: flex;
    max-width: 1400px;
    box-shadow: 0px 0px 6px 0px rgba(18, 18, 18, 0.5);
    transition: 0.3s;
    flex-direction: row;
    justify-content: space-between;
  }
  @media screen and (max-width: 767px) {
    .blog-post-card {
      flex-direction: column !important;
      width: 100%;
    }
    .container{
      padding: 12px !important;
    }
    .image{
      height: 200px !important;
    }
    .text{
      font-size: 1rem !important;
      text-align: center !important;
      margin-top: 0.5rem !important;
    }
    .text1{
      font-size: 0.85rem !important;
    }
    
  }
  .blog-post-card:hover {
    transform: scale(1.02);
    box-shadow: 0px 0px 10px 0px rgba(18, 18, 18, 0.6);
  }
  .image {
    /* width: 100%; */
    /* height: 100%; */
    height: 300px;
    min-width: 45%;
    /* max-height: 23rem; */
    object-fit: cover;
    border-radius: 0px;
  }
  .container {
    width: 100%;
    display: flex;
    padding: 32px;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--dl-color-gray-white);
  }
  .text {
    color: #393939;
    margin-top: var(--dl-space-space-unit);
    font-weight: 700;
    font-size: 1.2rem;
    margin-bottom: 0.2rem;
  }
  .text1 {
    font-size: 1.1rem;
    color: #696969;
    /* text-align: center; */
    font-weight: 300;
  }
  .separator {
    width: 40%;
    height: 1px;
    margin-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
    background-color: #595959;
    display: none;
  }
  .text2 {
    color: var(--dl-color-gray-700);
    font-size: 0.9rem;
    font-weight: 700;
    display: none;
  }