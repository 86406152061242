.footer-container {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-secondary-300);
}
.footer-container01 {
  width: 100%;
  display: flex;
  max-width: 1320px;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.footer-container02 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.footer-text {
  color: var(--dl-color-pimary-900);
  margin-top: var(--dl-space-space-unit);
  font-weight: 600;
}
.footer-text02 {
  color: var(--dl-color-pimary-900);
  margin-top: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
}
.footer-container03 {
  display: flex;
  margin-top: var(--dl-space-space-unitandhalf);
  align-items: flex-start;
  flex-direction: row;
}
.footer-container04 {
  flex: 0 0 auto;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  box-shadow: 0px 0px 5px 0px #d4d4d4;
  transition: 0.2s;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
  cursor: pointer;
  margin-right: 0.5rem;
}
.footer-container04:hover{
  box-shadow: 0 0 10px 0 #d4d4d4;
}
.footer-container04:hover {
  background-color: #80dcff;
}
.footer-icon {
  fill: #0674c6;
  width: 24px;
  height: 24px;
  align-self: center;
}
.footer-container05 {
  flex: 0 0 auto;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  box-shadow: 0px 0px 5px 0px #d4d4d4;
  align-items: center;
  margin-left: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
  cursor: pointer;
  transition: 0.2s;
  margin-right: 0.5rem;
}
.footer-container05:hover{
  box-shadow: 0 0 10px 0 #d4d4d4;
  background-color: #a7ffae;
}
.footer-icon2 {
  fill: #289400;
  width: 24px;
  height: 24px;
}
.footer-container06 {
  flex: 0 0 auto;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  box-shadow: 0px 0px 5px 0px #d4d4d4;
  align-items: center;
  margin-left: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
  cursor: pointer;
  transition: 0.2s;
  margin-right: 0.5rem;
}
.footer-container06:hover{
  box-shadow: 0 0 10px 0 #d4d4d4;
  background-color: #a1c7ff;
}
.footer-icon4 {
  fill: #4267B2;
  width: 20px;
  height: 20px;
}
.footer-container07 {
  flex: 0 0 auto;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  box-shadow: 0px 0px 5px 0px #d4d4d4;
  align-items: center;
  margin-left: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
  cursor: pointer;
  transition: 0.2s;
}
.footer-container07:hover{
  box-shadow: 0 0 10px 0 #d4d4d4;
  background-color: hsl(288, 100%, 82%);
}
.footer-icon6 {
  fill: #dc00a0;
  width: 24px;
  height: 24px;
}
.footer-container08 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.footer-container09 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  margin-right: 6rem;
  flex-direction: column;
}
.footer-text03 {
  /* color: var(--dl-color-pimary-900); */
  font-weight: 600;
  padding-bottom: var(--dl-space-space-halfunit);
  /* color: var(--dl-color-secondary-500); */
}
.footer-text04 {
  color: var(--dl-color-pimary-900);
  padding-bottom: var(--dl-space-space-halfunit);
  color: var(--dl-color-secondary-500);
}
.footer-text05 {
  color: var(--dl-color-pimary-900);
  padding-bottom: var(--dl-space-space-halfunit);
  color: var(--dl-color-secondary-500);
}
.footer-text06 {
  color: var(--dl-color-pimary-900);
  padding-bottom: var(--dl-space-space-halfunit);
  color: var(--dl-color-secondary-500);
}
.footer-text07 {
  color: var(--dl-color-pimary-900);
  color: var(--dl-color-secondary-500);
}
.footer-container10 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.footer-text08 {
  /* color: var(--dl-color-secondary-400); */
  font-weight: 600;
  padding-bottom: var(--dl-space-space-halfunit);
}
.footer-text09 {
  color: var(--dl-color-secondary-500);
  padding-bottom: var(--dl-space-space-halfunit);
}
.footer-text10 {
  color: var(--dl-color-secondary-500);
  padding-bottom: var(--dl-space-space-halfunit);
}
.footer-text11 {
  color: var(--dl-color-secondary-500);
  padding-bottom: var(--dl-space-space-halfunit);
}
.footer-text12 {
  color: var(--dl-color-secondary-500);
}

.footer-text04:hover, .footer-text05:hover, .footer-text06:hover, .footer-text07:hover, .footer-text09:hover, .footer-text10:hover, .footer-text11:hover, .footer-text12:hover {
  color: white;
  cursor: pointer;
}
.footer-container11 {
  flex: 0 0 auto;
  width: 100%;
  height: 1px;
  display: flex;
  margin-top: var(--dl-space-space-tripleunit);
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: column;
  background-color: #e3e8efff;
}
.footer-text13 {
  color: var(--dl-color-secondary-500);
  align-self: center;
}
.footer-root-class-name {
  background-color: var(--dl-color-secondary-300);
}
@media(max-width: 991px) {
  .footer-container01 {
    align-items: center;
    flex-direction: column;
  }
  .footer-container02 {
    align-items: center;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .footer-container09 {
    align-items: center;
  }
  .footer-container10 {
    align-items: center;
  }
}
@media(max-width: 767px) {
  .footer-text02 {
    text-align: center;
  }
  .footer-container08 {
    align-items: center;
    flex-direction: column;
  }
  .footer-container09 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .footer-container10 {
    align-items: center;
  }
}
