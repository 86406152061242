.blog{
  background-color: #1e293b1f;

}

.container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  .container1 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    background-size: cover;
    justify-content: flex-start;
    /* background-image: url("/playground_assets/unsplash-image1-1500w.jpeg"); */
  }
  .blog {
    width: 100%;
    display: flex;
    padding: 48px;
    max-width: 100%;
    align-items: center;
    justify-content: space-between;
  }
  .container2 {
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    margin-left: 32px;
    margin-right: 32px;
    flex-direction: column;
    justify-content: flex-start;
    gap: 32px;
  }
  @media(max-width: 767px) {
    .blog {
      padding-left: 32px;
      padding-right: 32px;
      flex-direction: column;
      gap: 28px;
    }
    .container2 {
      flex: 0 0 auto;
      margin-left: 0px;
      margin-right: 0px;
    }
  }
  @media(max-width: 479px) {
    .blog {
      padding-top: 32px;
      padding-left: var(--dl-space-space-unit);
      padding-right: var(--dl-space-space-unit);
      padding-bottom: 32px;
    }
  }