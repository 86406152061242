.home-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
}
.home-hero {
  flex: 0 0 auto;
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  /* background-size: cover; */
  /* background-image: url('http://www.duramaterials.in/files/pexels-richard-he-5567711-2.mp4'); */
}
.home-video {
  top: 0;
  left: 0;
  position: absolute;
  width: 100% !important;
  background-size: cover !important;
}
@media screen and (max-width: 500px) {
  .home-video {
    height: 100% !important;
    object-fit: cover;
    width: 100vw;
  }
}
.home-bg {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  opacity: 0.6;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-black);
}
.home-container01 {
  top: auto;
  left: auto;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-container02 {
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  justify-content: center;
}
.home-text {
  color: var(--dl-color-gray-white);
  text-align: center;
}
@media screen and (max-width: 500px) {
  .home-text{
    font-size: 1.8rem;
    line-height: 2.2rem;
    margin-bottom: 2rem;
  }
  .home-text01{
    font-size: 0.9rem;
    line-height: 1.3rem;
  }
}
.home-text01 {
  color: #ffffff;
  max-width: 700px;
  text-align: center;
  font-weight: 500;
}
.home-image {
  top: auto;
  left: auto;
  right: 0px;
  width: 100%;
  bottom: -1px;
  position: absolute;
  object-fit: cover;
  background-clip: polygon(0 0, 0 100px, 100px 80px, 100px 0);
}
.home-image1 {
  left: auto;
  right: 0px;
  width: 100%;
  bottom: 0px;
  display: none;
  position: absolute;
  object-fit: cover;
}
.home-section1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-tripleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  /* padding-bottom: var(--dl-space-space-fiveunits); */
  background-color: var(--dl-color-pimary-900);
  background-image: url('/playground_assets/asfalt-dark.png');
  /* opacity: 0.3; */
}
.home-container03 {
  width: 100%;
  display: flex;
  max-width: 1320px;
  align-items: center;
  flex-direction: column;
}
.home-container04 {
  width: 66%;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
}
.home-text04 {
  color: var(--dl-color-pimary-300);
  font-size: 0.875em;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}
.home-text05 {
  color: #000000;
  text-align: center;
}
.home-text06 {
  color: var(--dl-color-secondary-100);
  text-align: center;
  margin-bottom: 0.25rem;
}
.home-cards-container {
  display: flex;
  /* margin-top: var(--dl-space-space-tripleunit); */
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  /* margin-bottom: var(--dl-space-space-tripleunit); */
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unitandhalf);
  justify-content: space-between;
}
.home-card1 {
  flex: 0 0 auto;
  width: 30%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-unit);
  box-shadow: 0 10px 15px -3px rgba(0,0,0,0.1),0 1px 6px -2px rgba(0,0,0,0.5);
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius50);
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
  padding-top: var(--dl-space-space-doubleunit);
  padding-bottom: var(--dl-space-space-doubleunit);
  /* background-color: var(--dl-color-secondary-300); */
  /* background-color: var(--dl-color-secondary-700); */
}
.home-card1:hover {
  box-shadow: 0 1px 15px -1px rgba(0,0,0,0.3),0 1px 10px -2px rgba(0,0,0,0.5);
}
.home-container05 {
  flex: 0 0 auto;
  width: 5rem;
  height: 5rem;
  display: flex;
  box-shadow: 0 10px 15px -3px rgba(0,0,0,0.1),0 1px 6px -2px rgba(0,0,0,0.5);
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-image: linear-gradient(180deg, #f87171, #dc2626);
  /* background-color: var(--dl-color-secondary-300); */
  /* background-color: var(--dl-color-secondary-700); */
}
.home-icon {
  fill: var(--dl-color-gray-white);
  width: 30px;
  height: 30px;
}
.home-text07 {
  color: var(--dl-color-secondary-100);
  /* color: white; */
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  font-weight: 500;
  font-family: Open Sans;
}
.home-text08 {
  color: var(--dl-color-secondary-400);
  /* color: white; */
  font-size: 1rem;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
  font-family: Open Sans;
  line-height: 1.625rem;
  font-weight: 400;
}
.home-card2 {
  flex: 0 0 auto;
  width: 30%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-unit);
  box-shadow: 0 10px 15px -3px rgba(0,0,0,0.1),0 1px 6px -2px rgba(0,0,0,0.5);
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius50);
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
  padding-top: var(--dl-space-space-doubleunit);
  padding-bottom: var(--dl-space-space-doubleunit);
  /* background-color: var(--dl-color-secondary-300); */
  /* background-color: var(--dl-color-secondary-700); */
}
.home-card2:hover {
  /* box-shadow: 0 10px 15px -3px rgba(0,0,0,0.1),0 1px 10px -2px rgba(0,0,0,0.5); */
  box-shadow: 0 1px 15px -1px rgba(0,0,0,0.3),0 1px 10px -2px rgba(0,0,0,0.5);
}
.home-container06 {
  flex: 0 0 auto;
  width: 5rem;
  height: 5rem;
  display: flex;
  box-shadow: 0 10px 15px -3px rgba(0,0,0,0.1),0 1px 6px -2px rgba(0,0,0,0.5);
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-image: linear-gradient(180deg, #38bdf8, #0284c7);
}
.home-icon02 {
  fill: var(--dl-color-gray-white);
  width: 4rem;
  height: 4rem;
}
.home-text09 {
  color: var(--dl-color-secondary-100);
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  font-family: Open Sans;
  font-weight: 500;
}
.home-text10 {
  color: var(--dl-color-secondary-400);
  font-size: 1rem;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
  font-family: Open Sans;
  font-weight: 400;
  line-height: 1.625rem;
}
.home-card3 {
  flex: 0 0 auto;
  width: 30%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-unit);
  padding-top: var(--dl-space-space-doubleunit);
  padding-bottom: var(--dl-space-space-doubleunit);
  box-shadow: 0 10px 15px -3px rgba(0,0,0,0.1),0 1px 6px -2px rgba(0,0,0,0.5);
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius50);
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
  /* background-color: var(--dl-color-secondary-300); */
  /* background-color: var(--dl-color-secondary-700); */
}
.home-card3:hover {
  /* box-shadow: 0 10px 15px -1px rgba(0,0,0,0.1),0 1px 10px -2px rgba(0,0,0,0.5); */
  box-shadow: 0 1px 15px -1px rgba(0,0,0,0.3),0 1px 10px -2px rgba(0,0,0,0.5);
  /* color: white !important; */
}
.home-container07 {
  flex: 0 0 auto;
  width: 5rem;
  height: 5rem;
  display: flex;
  /* box-shadow: 0 10px 15px -3px rgba(0,0,0,0.1),0 1px 6px -2px rgba(0,0,0,0.5); */
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-image: linear-gradient(180deg, #34d399, #059669);
  /* padding-top: var(--dl-space-space-doubleunit); */
}
.home-icon04 {
  fill: var(--dl-color-gray-white);
  width: 4rem;
  height: 4rem;
}
.home-text13 {
  color: var(--dl-color-secondary-100);
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  font-weight: 500;
  font-family: Open Sans;
}
.home-text14 {
  color: var(--dl-color-secondary-400);
  font-size: 1rem;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
  font-family: Open Sans;
  font-weight: 400;
  line-height: 1.625rem;
}
@media screen and (max-width: 508px) {
  .home-text05{
    font-size: 1.6rem;
    margin-bottom: 1.4rem;
  }
  .home-text06{
    font-size: 0.95rem;
  }
  .home-text10, .home-text08{
    font-size: 0.9rem;
  }
  .home-text14{
    font-size: 0.9rem;
  } 

}
.home-work-with-us {
  width: 98vw;
  display: flex;
  margin-top: var(--dl-space-space-tripleunit);
  align-items: center;
  margin-left: 0;
  padding-top: 5rem;
  /* margin-bottom: var(--dl-space-space-halfunit); */
  flex-direction: row;
  padding-bottom: 5rem;
  /* padding-bottom: 8rem; */
  background-color: var(--dl-color-secondary-100);
  background-image: url('/playground_assets/tiles.png');
  margin-bottom: 2rem;
}
.home-container08 {
  flex: 0 0 auto;
  width: 41%;
  display: flex;
  align-items: flex-start;
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
}
.home-container09 {
  flex: 0 0 auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  box-shadow: 0 10px 15px -3px rgba(0,0,0,0.1),0 4px 6px -2px rgba(0,0,0,0.05);
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.home-icon06 {
  fill: var(--dl-color-secondary-500);
  width: 4rem;
  height: 4rem;
}
.home-text17 {
  color: var(--dl-color-pimary-700);
  font-weight: 600;
  margin-bottom: var(--dl-space-space-halfunit);
}
@media screen and (max-width: 768px) {
  .home-text17 {
    font-size: 1.5rem;
  }
  .home-icon06 {
    width: 2.5rem;
    height: 2.5rem;
  }
  .home-text18 {
    font-size: 0.9rem;
  }
  .home-text25 {
    font-size: 0.9rem;
  }
}
.home-text18 {
  color: var(--dl-color-pimary-900);
  margin-top: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-unit);
}
.home-text25 {
  color: var(--dl-color-pimary-700);
  font-style: normal;
  font-weight: 700;
}
.home-container10 {
  flex: 0 0 auto;
  width: 33%;
  display: flex;
  position: relative;
  box-shadow: 0 10px 15px -3px rgba(0,0,0,0.1),0 4px 6px -2px rgba(0,0,0,0.05);
  align-items: flex-start;
  margin-left: auto;
  margin-right: auto;
  border-radius: var(--dl-radius-radius-radius50);
  flex-direction: column;
  background-color: #19a8ef;
  transition: 0.2s;
}
@media screen and (max-width: 508px) {
  .home-container10 {
    width: 90% !important;
  }  
}
.home-container10:hover{
  box-shadow: 0px 0px 4px white;
}
.home-container11 {
  width: 100%;
  height: 360px;
  display: flex;
  align-items: flex-end;
  border-radius: 0px;
  flex-direction: column;
  background-size: cover;
  justify-content: flex-end;
  background-image: url('https://images.unsplash.com/photo-1536895058696-a69b1c7ba34f?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDN8fGNvbnN0cnVjdGlvbnxlbnwwfHx8fDE2Nzk3MjM3MDA&ixlib=rb-4.0.3&w=1500');
  /* background-image: url("https://zaitoon.com.pk/wp-content/uploads/2019/07/Building-Materials-3.jpg"); */
  background-position: center;
  border-top-left-radius: var(--dl-radius-radius-radius50);
  border-top-right-radius: var(--dl-radius-radius-radius50);
}
.home-image2 {
  top: auto;
  left: auto;
  width: 100%;
  bottom: 0px;
  /* object-fit: cover; */
  margin-bottom: -1px;
}
.home-container12 {
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.home-text26 {
  font-size: 1.25rem;
}
.home-text27 {
  color: #f2f5f9ff;
  font-weight: 700;
  white-space: normal;
}
.home-text28 {
  color: rgb(242, 245, 249);
  margin-top: var(--dl-space-space-unit);
}
.home-image3 {
  top: auto;
  left: auto;
  right: 0px;
  width: 100%;
  bottom: -1px;
  position: absolute;
  object-fit: cover;
  margin-top: -10rem;
}
.home-section2 {
  /* margin-top: -5rem; */
  width: 100%;
  height: 735px;
  display: flex;
  align-self: center;
  align-items: center;
  /* padding-top: var(--dl-space-space-doubleunit); */
  flex-direction: column;
  /* padding-bottom: var(--dl-space-space-doubleunit); */
  background-image: url('/playground_assets/asfalt-dark.png');
}
@media screen and (max-width: 508px) {
  .home-section2 {
    /* min-height: 735px; */
    height: 100%;
  }
}
.home-container13 {
  flex: 0 0 auto;
  width: 66%;
  display: flex;
  margin-top: 4rem;
  align-items: center;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.home-text31 {
  color: rgb(249, 115, 22);
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
}
.home-text32 {
  color: var(--dl-color-secondary-400);
  text-align: center;
}
.home-text33 {
  color: var(--dl-color-secondary-700);
  text-align: center;
  margin-bottom: 0.25rem;
}
@media screen and (max-width: 508px) {
  .home-text31{
    font-size: 0.8rem;
  }
  .home-text32{
    font-size: 1.5rem;
    margin-bottom: 1.4rem;
  }
  .home-text33{
    font-size: 1rem;
  }
}
.home-team {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.home-container14 {
  width: 100%;
  display: flex;
  max-width: 1320px;
  align-items: flex-start;
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
}
.home-navlink {
  display: contents;
}
.home-container15 {
  width: 50%;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.home-container16 {
  flex: 0 0 auto;
  display: flex;
  margin-top: var(--dl-space-space-unitandhalf);
  align-items: flex-start;
  flex-direction: row;
}
.home-container17 {
  flex: 0 0 auto;
  width: 2rem;
  cursor: pointer;
  height: 2rem;
  display: flex;
  align-items: center;
  margin-right: 0.25rem;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: #BD195D;
  transition: 0.15s;
}
.home-container17:hover {
  box-shadow: 0 0 12px #BD195D;
}
.home-icon08 {
  fill: var(--dl-color-gray-white);
  width: 18px;
  height: 18px;
}
.home-container18 {
  flex: 0 0 auto;
  width: 2rem;
  cursor: pointer;
  height: 2rem;
  display: flex;
  align-items: center;
  margin-right: 0.25rem;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: #4aa4e3ff;
  transition: 0.15s;
}
.home-container18:hover {
  box-shadow: 0 0 12px #4aa4e3ff;
}
.home-icon10 {
  fill: var(--dl-color-gray-white);
  width: 18px;
  height: 18px;
}
.home-navlink1 {
  display: contents;
}
.home-container19 {
  width: 50%;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.home-container20 {
  flex: 0 0 auto;
  display: flex;
  margin-top: var(--dl-space-space-unitandhalf);
  align-items: flex-start;
  flex-direction: row;
}
.home-container21 {
  flex: 0 0 auto;
  width: 2rem;
  cursor: pointer;
  height: 2rem;
  display: flex;
  align-items: center;
  margin-right: 0.25rem;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: #37BCF8;
  transition: 0.15s;
}
.home-container21:hover {
  box-shadow: 0 0 12px #37BCF8;
}
.home-icon12 {
  fill: var(--dl-color-gray-white);
  width: 18px;
  height: 18px;
}
.home-container22 {
  flex: 0 0 auto;
  width: 2rem;
  cursor: pointer;
  height: 2rem;
  display: flex;
  align-items: center;
  margin-right: 0.25rem;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: #4aa4e3ff;
  transition: 0.15s;
}
.home-container22:hover {
  box-shadow: 0 0 12px #4aa4e3ff;
}
.home-icon14 {
  fill: var(--dl-color-gray-white);
  width: 18px;
  height: 18px;
}
.home-container23 {
  flex: 0 0 auto;
  width: 2rem;
  cursor: pointer;
  height: 2rem;
  display: flex;
  align-items: center;
  margin-right: 0.25rem;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: #BD195D;
  transition: 0.15s;
}
.home-container23:hover {
  box-shadow: 0 0 12px #BD195D;
}
.home-icon16 {
  fill: var(--dl-color-gray-white);
  width: 18px;
  height: 18px;
}
@media(max-width: 991px) {
  .home-cards-container {
    align-items: center;
    flex-direction: column;
  }
  .home-card1 {
    width: 90%;
    margin-bottom: var(--dl-space-space-fiveunits);
  }
  .home-card2 {
    width: 90%;
    margin-bottom: var(--dl-space-space-fiveunits);
  }
  .home-card3 {
    width: 90%;
  }
  .home-section2 {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
}
@media(max-width: 767px) {
  .home-section1 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-container04 {
    width: 100%;
  }
  .home-work-with-us {
    margin-top: var(--dl-space-space-tripleunit);
    flex-direction: column;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .home-container08 {
    width: 100%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-container10 {
    width: 100%;
  }
  .home-container13 {
    width: auto;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-container14 {
    flex-wrap: wrap;
    flex-direction: row;
  }
  .home-container15 {
    width: 50%;
    padding-bottom: var(--dl-space-space-tripleunit);
  }
  .home-container19 {
    width: 50%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
}
@media(max-width: 479px) {
  .home-container02 {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .home-card1 {
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-text08 {
    text-align: left;
  }
  .home-card2 {
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-text10 {
    text-align: left;
  }
  .home-text14 {
    text-align: left;
  }
  .home-container14 {
    align-items: center;
    flex-direction: column;
  }
}
