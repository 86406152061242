@media screen and (min-width: 568px){   
    .contact-container {
        /* background-color: '#111729ff !important'; */
    }
}

.contact-details-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 40px;
    text-align: center;
    /* margin: 0 auto; */
    width: 100%;
    /* height: 90vh; */
    /* background-color: rgba(36, 168, 185, 0.2); */
    background-image: url("/playground_assets/brick-wall.png");
    /* background-color: #1e293b2f; */
    /* background-color: rgba(21, 172, 121, 0.5); */
    /* margin-top: var(--dl-space-space-twounits); */
    /* padding-top: var(--dl-space-space-twounits); */
    /* padding-bottom: var(--dl-space-space-twounits); */
}

@media screen and (max-width: 768px) {

    .contact-details-left-container-title{
        padding-right: 0px !important;
        padding-left: 0px !important;
        padding-bottom: 0 !important;
    }
    .contact-details-right-container-title{
        /* border-top: 1px solid #ddd; */
        /* border-left: 0px solid #ddd; */
        padding-top: 0 !important;
        margin-top: 0 !important;
        padding-left: var(--dl-space-space-twounits) !important;
        padding-right: 0px;
    }
    .contact-details-left-container-title-address{
        grid-template-columns: 1fr !important;
    }
}

.contact-details-left-container-title{
    padding-right: var(--dl-space-space-unit);
    /* padding-left: 1.5rem; */
}

.contact-details-left-container-title h1{
    font-size: 2.5rem;
    font-weight: 700;
    color: #000;
    /* margin-bottom: var(--dl-space-space-unit); */
}

.contact-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 0.7rem !important;
    padding-right: 0.7rem !important;
}
.contact-item p{
    font-size: 0.9rem !important;
}

@media screen and (max-width: 768px) {
    .contact-item {
        width: 90vw;
    }    
}

.contact-subline2{
    font-size: 1rem;
    font-weight: 400;
    margin-top: 0.5rem;
    color: #000;
    margin-bottom: var(--dl-space-space-twounits);
    margin-left: 2rem;
}

@media screen and (max-width: 768px) {
    .contact-subline2 {
        margin-left: 0 !important;
        display: none;
    }
}

.contact-details-left-container-title h3{
    font-size: 1.1rem;
    /* font-weight: 400; */
    color: #000;
    margin-top: 0.5rem;
}

.contact-details-left-container-title-address{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1rem;
    margin: 2rem 6rem;
    margin-top: 1.5rem;
}

.contact-details-left-container-title-address div{
    background: #0382b7;
    color: white;
    border-radius: 10px;
    padding-top: 2rem;
    padding-bottom: 1.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    align-items: center;
}

.contact-details-left-container-title h3{
    color: white
}

.contact-details-left-container-title-address p{
    font-size: 0.85rem;
    font-weight: 400;
    color: white;
}

.contact-details-right-container-title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    margin-top: 20px;
    width: 70vw;
    color: #fff;
    /* height: 90vh; */
    /* background-color: #f5f5f5; */
    /* background-color: rgba(36, 168, 185, 0.3); */
    /* margin-top: var(--dl-space-space-twounits); */
    padding-right: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-fourunits);
    padding-top: 3rem;
    padding-bottom: 3rem;
    border-left: 1px solid #777;
    background-image: url("/playground_assets/diamonds.png");
    background-color: rgba(0,0,0,0.3);
}

@media screen and (max-width: 768px) {
    .contact-details-right-container-title{
        width: 85vw;
        padding-top: 2rem !important;
        padding-bottom: 2rem !important;
    }
}

.contact-details-right-container-title div div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    /* height: 100vh; */
    /* background-color: #f5f5f5; */
    /* margin-top: var(--dl-space-space-twounits); */
    /* padding-top: var(--dl-space-space-twounits); */
    padding-bottom: var(--dl-space-space-twounits);
}

.contact-details-right-container-title div div input{
    width: 100%;
    height: 40px;
    margin-top: 15px !important;
    border: 0.01px solid #ddd;
    border-radius: 5px;
    padding-left: 10px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    border-width: 0.1px;
    border-radius: 0px;
    border-color: #111729ff;
}

.contact-details-right-container-title div div textarea{
    width: 100%;
    height: 100px;
    border: 0.01px solid #ddd;
    margin-top: 15px !important;
    border-radius: 5px;
    padding-left: 10px;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
    border-width: 0.1px;
    border-radius: 0px;
    border-color: #111729ff;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.contact-details-right-container-title div div button{
    /* width: 100%; */
    /* height: 40px; */
    border: 0.01px solid #ddd;
    /* border-radius: 8px; */
    /* padding-left: 10px; */
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    background-color: #000;
    color: #fff;
    font-weight: 700;
    font-size: 1rem;
    padding: 12px 36px;
    cursor: pointer;
    transition-duration: 150ms;
    margin-top: 20px !important;
}

.contact-details-right-container-title div div button:hover{
    background-color: #fff;
    color: #000;
    border: 1px solid #000;
}

.contact-details-right-container-title-input input{
    margin-top: var(--dl-space-space-twounits) !important;
}

@media screen and (max-width: 768px) {
    .contact-details-right-container-title{
        padding-left: 3rem !important;
    }
    .contact-details-right-container-title div div button {
        margin-top: 2rem !important;
    }
}