.member-details-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.member-details-image {
  width: 130px;
  box-shadow: 0 10px 15px -3px rgba(0,0,0,0.1),0 4px 6px -2px rgba(0,0,0,0.05);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.member-details-text {
  color: var(--dl-color-secondary-100);
  text-align: center;
  font-weight: 500;
  padding-top: var(--dl-space-space-unitandhalf);
}
.member-details-text1 {
  color: var(--dl-color-secondary-400);
  font-weight: 400;
  margin-bottom: var(--dl-space-space-halfunit);
  text-align: center;
  text-transform: uppercase;
}

.member-details-text2{
  color: var(--dl-color-secondary-500);
  font-size: 0.7rem;
  padding-left: 5rem;
  padding-right: 5rem;
  line-height: 1rem;
  /* font-weight: 400; */
  text-align: center;
  text-transform: uppercase;
  padding-top: var(--dl-space-space-halfunit);
}

@media screen and (max-width: 508px) {
  .member-details-container{
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 80vw;
  }
  .member-details-text {
    font-size: 1.4rem;
    line-height: 1.8rem;
    /* padding: 0 !important; */
  }
  .member-details-text1 {
    font-size: 0.85rem;
    line-height: 1.2rem;
    padding: 0 !important;
    margin-bottom: 1.2rem;
  }
  .member-details-text2 {
    font-size: 0.7rem;
    line-height: 1.2rem;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding: 0 !important;
  }  
}