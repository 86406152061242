.feature-card1-feature-card {
    width: 100%;
    min-width: max-content;
    display: flex;
    padding: 2rem;
    /* padding-top: 2rem; */
    /* padding-bottom: 2rem; */
    max-width: 1400px;
    box-shadow: 2px 2px 4px 0px rgba(18, 18, 18, 0.3);
    transition: 0.3s;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background: #fff;
    border-radius: 0.7rem;
    /* border-left: rgb(8, 21, 82) 5px solid; */
    border-bottom: rgb(8, 21, 82) 5px solid;
    /* border-right: rgb(8, 21, 82) 5px solid; */
  }
  .rootClassName5 img{
    width: 200px;
    height: 200px;
  }
  .feature-card1-feature-card:hover {
    transform: scale(1.02);
    box-shadow: 3px 3px 10px 0px rgba(18, 18, 18, 0.3);
  }
  .feature-card1-text {
    color: var(--dl-color-secondary-100);
    margin-bottom: 32px;
  }
  .feature-card1-image {
    max-width: 180px;
    height: 130px;
    object-fit: contain;
    border-radius: 4px;
  }

  @media screen and (max-width: 591px) {
    .feature-card1-feature-card {
        margin-bottom: 1rem;
    }
    .feature-card1-feature-card{
        min-width: min-content !important;
    }
  }