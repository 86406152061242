.header-header {
  flex: 0 0 auto;
  width: 100%;
  height: 75px;
  display: flex;
  padding: var(--dl-space-space-unitandhalf);
  z-index: 1000 !important;
  max-width: 1320px;
  align-items: center;
  /* margin-bottom: 0.75rem; */
  flex-direction: row;
  justify-content: space-between;
}
.header-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.header-navlink {
  display: contents;
}
.header-heading {
  color: var(--dl-color-gray-white);
  cursor: pointer;
  font-size: 1rem;
  font-weight: 700;
  margin-right: var(--dl-space-space-unitandhalf);
  text-decoration: none;
}
.header-navlink1 {
  display: contents;
}
.header-container1 {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-unit);
  flex-direction: row;
  text-decoration: none;
}
.header-icon {
  fill: var(--dl-color-gray-900);
  width: 17px;
}
.header-text {
  color: var(--dl-color-gray-900);
  margin-left: var(--dl-space-space-halfunit);
  font-weight: 500;
}
.header-navlink2 {
  display: contents;
}
.header-container2 {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-unit);
  flex-direction: row;
  text-decoration: none;
}
.header-icon02 {
  fill: var(--dl-color-gray-900);
  width: 17px;
}
.header-text1 {
  color: var(--dl-color-gray-900);
  margin-left: var(--dl-space-space-halfunit);
  font-weight: 500;
}
.header-navlink3 {
  display: contents;
}
.header-container3 {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-unit);
  flex-direction: row;
  text-decoration: none;
}
.header-icon07 {
  fill: var(--dl-color-gray-900);
  width: 17px;
}
.header-text2 {
  color: var(--dl-color-gray-900);
  margin-left: var(--dl-space-space-halfunit);
  font-weight: 500;
}
.header-navlink4 {
  display: contents;
}
.header-container4 {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-unit);
  flex-direction: row;
  text-decoration: none;
}
.header-icon12 {
  fill: var(--dl-color-gray-900);
  width: 17px;
}
.header-text3 {
  color: var(--dl-color-gray-900);
  margin-left: var(--dl-space-space-halfunit);
  font-weight: 500;
}
.header-text3:hover{
  color: white
}
.header-navlink5 {
  display: contents;
}
.header-container5 {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-halfunit);
  flex-direction: row;
  text-decoration: none;
}
.header-icon17 {
  fill: var(--dl-color-gray-900);
  width: 17px;
}
.header-text4 {
  color: var(--dl-color-gray-900);
  margin-left: var(--dl-space-space-halfunit);
  font-weight: 500;
}
.header-text:hover, .header-text1:hover, .header-text2:hover, .header-text3:hover, .header-text4:hover {
  color: white;
}
.header-burger-menu {
  display: none;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.header-icon22 {
  width: 24px;
  height: 24px;
}
.header-container6 {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.header-icon24 {
  fill: var(--dl-color-gray-900);
  width: 24px;
  cursor: pointer;
  height: 24px;
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  margin-bottom: var(--dl-space-space-halfunit);
}
.header-icon24:hover {
  fill: white !important;
  /* color: white; */
}
.header-icon26 {
  fill: var(--dl-color-gray-900);
  /* fill: grey; */
  width: 24px;
  cursor: pointer;
  height: 24px;
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  margin-bottom: var(--dl-space-space-halfunit);
}
.header-icon26:hover {
  fill: white !important;
}
.header-icon28 {
  fill: #D9D9D9;
  width: 24px;
  cursor: pointer;
  height: 24px;
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  margin-left: var(--dl-space-space-triplequarter);
  margin-right: var(--dl-space-space-triplequarter);
  margin-bottom: var(--dl-space-space-halfunit);
}
.header-icon28:hover {
  /* color: white; */
  fill: white !important;
}
.header-mobile-menu {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-unitandhalf);
  z-index: 100;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  /* background-color: #333; */
  background: linear-gradient(180deg, #333 0%, #000000 100%);
}
.header-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
  justify-content: space-between;
}
.header-navlink6 {
  display: contents;
}
.header-heading1 {
  color: var(--dl-color-secondary-400);
  color: white;
  font-weight: 700;
  margin-right: var(--dl-space-space-unit);
  text-decoration: none;
}
.header-close-menu {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.header-icon30 {
  width: 24px;
  height: 24px;
  fill: white
}
.header-mid {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.7rem;
  margin-top: 1rem;
}
.header-navlink7 {
  display: contents;
}
.header-container7 {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  flex-direction: row;
  text-decoration: none;
}
.header-icon32 {
  fill: white;
  width: 13px;
}
.header-text5 {
  color: white;
  margin-left: var(--dl-space-space-halfunit);
}
.header-navlink8 {
  display: contents;
}
.header-container8 {
  display: flex;
  align-items: center;
  flex-direction: row;
  text-decoration: none;
}
.header-icon34 {
  fill: var(--dl-color-secondary-400);
  width: 13px;
}
.header-text6 {
  color: white;
  margin-left: var(--dl-space-space-halfunit);
}
.header-bot {
  flex: 0 0 auto;
  display: flex;
  margin-top: auto;
  align-items: center;
  flex-direction: row;
}
.header-icon39 {
  fill: var(--dl-color-secondary-400);
  fill: white;
  width: 24px;
  height: 24px;
  margin-top: var(--dl-space-space-halfunit);
  margin-right: 0.75rem;
  margin-bottom: var(--dl-space-space-halfunit);
}
.header-icon41 {
  fill: var(--dl-color-secondary-400);
  fill: white;
  width: 24px;
  height: 24px;
  margin-top: var(--dl-space-space-halfunit);
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  margin-bottom: var(--dl-space-space-halfunit);
}
.header-icon43 {
  fill: var(--dl-color-secondary-400);
  fill: white;
  width: 24px;
  height: 24px;
  margin-top: var(--dl-space-space-halfunit);
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  margin-bottom: var(--dl-space-space-halfunit);
}
.header-root-class-name {
  margin-bottom: 0px;
}
@media(max-width: 991px) {
  .header-header {
    max-width: 1320px;
    margin-bottom: var(--dl-space-space-triplequarter);
    border-bottom: 0 !important;
  }
  .header-icon24 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .header-icon26 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .header-icon39 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .header-icon41 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .header-icon43 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}
.darkLogo {
  display: none;
}
@media(max-width: 767px) {
  /* .whiteLogo {
    display: none;
  }
  .darkLogo {
    display: block;
  } */
  .header-header {
    /* background-color: var(--dl-color-gray-white); */
    background-color: transparent;
  }
  .header-heading {
    color: var(--dl-color-secondary-400);
  }
  .header-container1 {
    display: none;
  }
  .header-container2 {
    display: none;
  }
  .header-container3 {
    display: none;
  }
  .header-container4 {
    display: none;
  }
  .header-container5 {
    display: none;
  }
  .header-burger-menu {
    display: flex;
  }
  .header-icon22 {
    /* fill: var(--dl-color-secondary-400); */
    fill: white;
  }
  .header-container6 {
    display: none;
  }
  .header-root-class-name {
    margin-bottom: 0px;
  }
}
